<template>
  <div class="container py-5">
    <div class='embed-container'><iframe width='560' height='315' src='https://www.youtube-nocookie.com/embed/TJlLc0e4bvE' frameborder='0' allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe></div>
    <br>
    <img
    class="img-max rounded"
    src="@/assets/images/zh-TW/news/new_21_1.png"
     />
     <br><br>
    <p class="fw-bold">【OBSERV®｜2023 AMWC參展】</p>
    <p>
      AMWC Asia-TDAC 2023 即將在五月盛大開展！
      <br>
      今年國門開放，AMWC展會將有更多國際與會者前來，歡迎醫師至OBSERV C23展位一同交流OBSERV全新技術。
    </p>
    <p><b>本次Workshop將會特邀四位醫師針對OBSERV全新技術及其應用進行分享，敬請撥冗參與。</b></p>
    <br>
    <p class="fw-bold">AMWC Asia-TDAC 2023</p>
    <p>
      時間：5/6-7<br>
      地點：台北國際會議中心(TICC)<br>
      展位：C23 (Level 3)
    </p><br>
    <p class="fw-bold">OBSERV® Workshop</p>
    <p>
      時間：5/6 11:30-12:30<br>
      地點：201D (Level 2)<br>
    </p>



  </div>
</template>
<script>
export default {};
</script>
